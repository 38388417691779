var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "iframe-panel-container",
      class: { "fill-iframe": _vm.src === "top-cockpit" },
    },
    [
      _vm.iframeVisible
        ? _c("iframe", {
            staticClass: "iframe",
            attrs: { id: "iframe", src: _vm.toSrc, frameborder: "0" },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }