<template>
  <div class="iframe-panel-container" :class="{'fill-iframe': src === 'top-cockpit'}">
    <iframe class="iframe" id="iframe" v-if="iframeVisible" :src="toSrc" frameborder="0" />
  </div>
</template>

<script>
import qs from 'qs'
export default {
  name: 'iframe-panel',
  data () {
    return {
      src: '',
      classLevel: '',
      orgId: '',
      iframeVisible: true,
      loginData: {}
    }
  },
  watch: {
    '$route': {
      handler (newValue, oldValue) {
        const { src } = newValue.query
        this.src = src
        this.iframeVisible = false
        this.$nextTick(() => {
          this.iframeVisible = true
        })
      },
      immediate: true
    }
  },
  computed: {
    toSrc () {
      let mainUrl = this.src
      if (this.src === 'top-cockpit') {
        mainUrl = `${API_CONFIG.origin.slice(0, -1)}/pm2/main?token=${localStorage.getItem('token')}&classLevel=${this.classLevel}&orgId=${this.orgId}`
      } else if (this.src === 'top-alicockpit') { // 闲林驾驶舱
        mainUrl = `${API_CONFIG.origin.slice(0, -1)}/pm2xianlin/main?token=${localStorage.getItem('token')}&classLevel=${this.classLevel}&orgId=${this.orgId}`
      } else if (this.src === 'cockpitNeUrl') {
        const token = localStorage.getItem('token');
        const companyCode = localStorage.getItem('companyCode');
        const signInfo = JSON.parse(localStorage.getItem('signInfo'));
        const communityId = this.$store.state.userInfo.communityId;
        mainUrl=`${API_CONFIG.origin.slice(0, -1)}/hx-cockpit/index.html#/?token=${token}&companyCode=${companyCode}&communityId=${communityId}&sign=${signInfo.sign}&appId=${signInfo.appId}&timestamp=${signInfo.timestamp}`
        // mainUrl = `http://172.16.17.86:8080/#/?token=${token}&companyCode=${companyCode}&communityId=${communityId}&sign=${signInfo.sign}&appId=${signInfo.appId}&timestamp=${signInfo.timestamp}`
      } else if (this.src === 'cockpitLanLvUrl') {
          const token = localStorage.getItem('token');
          const companyCode = localStorage.getItem('companyCode');
          const signInfo = JSON.parse(localStorage.getItem('signInfo'));
          const communityId = this.$store.state.userInfo.communityId;
          const orgId = this.$store.state.userInfo.orgId || '';
          mainUrl=`${API_CONFIG.origin.slice(0, -1)}/hx-cockpit/index.html#/LLIndex?token=${token}&orgId=${orgId}&companyCode=${companyCode}&communityId=${communityId}&sign=${signInfo.sign}&appId=${signInfo.appId}&timestamp=${signInfo.timestamp}`
          // mainUrl = `http://172.16.17.86:8080/#/?token=${token}&companyCode=${companyCode}&communityId=${communityId}&sign=${signInfo.sign}&appId=${signInfo.appId}&timestamp=${signInfo.timestamp}`
      } else {
          let menuInfo = this.$store.getters['menuStore/getMenuInfo'](this.$route.query.fid)?
          this.$store.getters['menuStore/getMenuInfo'](this.$route.query.fid):this.$store.getters['menuStore/getMenuInfo'](this.$route.query.sid)

          console.log('++++menuInfo', menuInfo)
          if (menuInfo) {
              const userInfo = this.$store.state.userInfo
              const permission = []
              for (const key in menuInfo.permission) {
                  const element = menuInfo.permission[key]
                  if(element === 1) {
                      permission.push(key)
                  }
              }
              const params = {
                  permission: permission,
                  id: userInfo.id,
                  tenantId: userInfo.orgId,
              }
              // this.src = 'http://172.16.11.176:8080/#/workOrderSchedule/serviceOrderList'
              // this.src = 'http://172.16.11.176:8080/#/workOrderSchedule/orderSet'
              // this.src = 'http://172.16.11.176:8080/#/workOrderSchedule/revisitList'
              // this.src = 'http://172.16.11.176:8080/#/workOrderSchedule/serviceOrderForm'
              mainUrl = this.src + `?token=${this.$store.state.token}&client=ysh-pc&userInfo=${JSON.stringify(params)}&${qs.stringify(this.$route.query)}`
              console.log(mainUrl, '--------')
              window.addEventListener('message',this.postMessage)
          } else {
              mainUrl = this.src;
          }
      }
      // 改为以外链的形式在浏览器另外打开新页面展示
      // if (this.src === 'top-xlwcockpit') { // 杏林湾驾驶舱
      //   // mainUrl = `${API_CONFIG.origin.slice(0, -1)}/pm2-xlw/main?token=${localStorage.getItem('token')}&classLevel=5&orgId=${this.orgId}`
      //   // 设置杏林湾小区驾驶舱查看条件：
      //   // 项目账号 (userType = 101) 且 只有杏林湾小区才可以查看 (communityId=d93ef0d8-647b-11eb-bdca-005056b13afd)
      //   // mainUrl = 'http://hxyt.lcfw.co:8000/publish/840bc8e95db84add963d5e3fff32c0a5'
      // } else
      return mainUrl
    }
  },
  async created () {
    // const apiUrl = `/cockpit/v3/getKeyByUserId`
    // const res = await this.$axios.get(apiUrl)
    // console.log(res)
    // if (res.status === 100 || res.status === '100') {
    //   let { classLevel, orgId } = res.data || {}
    //   this.classLevel = classLevel || ''
    //   this.orgId = orgId || ''
    // }
  },
  methods: {
    postMessage(e) {
      const data = e.data
      if(data.method === 'push') {
        // const iframe = document.querySelector('#iframe')
        // iframe.contentWindow.postMessage({
        //   from: '3后台',
        //   permission: menuInfo.permission,
        //   userInfo: {
        //     id: userInfo.id,
        //     tenantId: userInfo.orgId,
        //   }
        // },'*')
        this.$router.push({
          name: data.name,
          query: data.params
        })
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('message', this.postMessage)
  }
}
</script>

<style lang="scss" scoped>
.iframe-panel-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  text-align: left;
  padding: 0 20px;
  .iframe {
    width: 100%;
    height: 100%;
    flex: 1;
  }
}
.fill-iframe{
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  padding: 0;
  height: calc(100% - 60px) !important;
  background-color: #0f0759;
  .iframe {
    width: 100%;
    height: 100%;
    flex: 1;
  }
}
</style>
